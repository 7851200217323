import React from 'react'
import {
  Parallax,
  type ParallaxProps as RParallaxProps
} from 'react-scroll-parallax'
import Container from '~/components/Container'
import Typography from '~/components/Typography'
import { ROUTES } from '~/constants/routes'
import { cn } from '~/utils/cn'
import LearnMoreLink from './LearnMoreLink'

export type ParallaxSectionProps = PropsWithClassName<{
  imgProps: React.ComponentPropsWithoutRef<'img'>
  title: string
  stepNumber: number
  descriptions: [React.ReactNode, React.ReactNode]
  learnMoreTo: ValuesOf<typeof ROUTES>
  ParallaxProps: RParallaxProps
  ContentProps: React.ComponentPropsWithoutRef<'div'>
}>

const ParallaxSection = React.forwardRef(
  (
    {
      imgProps,
      title,
      stepNumber,
      descriptions,
      learnMoreTo,
      className,
      ParallaxProps,
      ContentProps
    }: ParallaxSectionProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <section
        className={cn('relative flex flex-col md:min-h-screen', className)}
        aria-labelledby="check-up-title"
        ref={ref}
      >
        <img
          loading="lazy"
          alt=""
          {...imgProps}
          className={cn(
            'w-full object-cover md:absolute md:inset-0 md:h-full',
            imgProps.className
          )}
        />
        <Container
          {...ContentProps}
          className={cn('relative flex flex-col py-12', ContentProps.className)}
        >
          <Parallax
            {...ParallaxProps}
            className={cn(
              'flex flex-col gap-6 max-md:!transform-none md:gap-8',
              ParallaxProps.className
            )}
          >
            <div className="flex gap-x-4">
              <Typography
                className="tabular-nums text-white/30"
                variant="h2"
                translate="no"
                family="brand"
                component="span"
              >
                0{stepNumber}
              </Typography>
              <Typography
                className="text-white"
                variant="h2"
                id="check-up-title"
                family="brand"
                component="h2"
              >
                {title}
              </Typography>
            </div>
            <Typography
              className="leading-[1.5em] text-white"
              variant="h6"
              component="p"
            >
              {descriptions[0]}
            </Typography>
            <Typography
              className="border-white/30 leading-[1.5em] text-white/90 lg:border-l lg:pl-9"
              variant="body2"
              component="p"
            >
              {descriptions[1]}
            </Typography>
            <LearnMoreLink to={learnMoreTo} />
          </Parallax>
        </Container>
      </section>
    )
  }
)

export default ParallaxSection
